module.exports = {
  "defaultLanguage": "fr-FR",
  "languages": [
    {
      "code": "en-US",
      "name": "English",
      "icon": null
    },
    {
      "code": "fr-FR",
      "name": "Français",
      "icon": null
    },
    {
      "code": "de-DE",
      "name": "Deutsch",
      "icon": null
    },
    {
      "code": "it-IT",
      "name": "Italiano",
      "icon": null
    }
  ]
}